import React from "react";
import { useTheme } from "@mui/material";

export function DiffStar(): React.ReactElement {
  const theme = useTheme();

  return (
    <svg
      height="75"
      viewBox="0 0 75 75"
      width="75"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 1785" transform="translate(-139.5 -4000)">
        <circle
          cx="37.5"
          cy="37.5"
          data-name="Ellipse 284"
          fill={theme.palette.primary.light}
          r="37.5"
          transform="translate(139.5 4000)"
        />
        <path
          d="M455.161 897.984A.851.851 0 01455 898a.838.838 0 01-.161-.016c-.769-.146-18.839-5.441-18.839-16.394a77 77 0 013.488-20.964.864.864 0 011.216-.536 32.059 32.059 0 0028.592 0 .865.865 0 011.217.536A77.056 77.056 0 01474 881.591c0 10.953-18.069 16.248-18.839 16.393zm13.953-35.91a33.607 33.607 0 01-28.228 0c-.8 3.065-3.159 11.075-3.159 19.516 0 9.041 15.506 14.292 17.272 14.664 1.766-.371 17.274-5.633 17.274-14.664 0-8.446-2.358-16.452-3.159-19.516zm-6.781 27.236l-7.36-5.4-7.361 5.4 2.944-8.832-6.379-4.962h7.851l2.944-7.8 2.944 7.8h7.851l-6.38 4.962zm-1.6-12.067h-4.011l-.421-1.117-1.328-3.518-1.328 3.518-.422 1.117h-4.01l2.4 1.871.965.75-.386 1.16-1.228 3.683 2.984-2.188 1.022-.749 1.021.749 2.984 2.188-1.227-3.683-.387-1.16.965-.75z"
          data-name="Sheild@2x.png"
          fill={theme.palette.primary.contrastText}
          fillRule="evenodd"
          transform="translate(-279 3160)"
        />
      </g>
    </svg>
  );
}
